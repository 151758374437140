<template>
  <main-container>
    <tabbed-display
      title="Biography"
      :tabList="sectionList"
      @select-tab="selectTab"
    >
      <v-card class="my-2" outlined>
        <v-card-text v-html="bioSection" class="black--text text-left">
        </v-card-text>
      </v-card>
    </tabbed-display>
  </main-container>
</template>

<script>
import marked from "marked";
import MainContainer from "../components/MainContainer.vue";
import TabbedDisplay from "../components/DataDisplays/TabbedDisplay.vue";

const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  return (
    '<a target="_blank" href="' +
    href +
    '" title="' +
    title +
    '">' +
    text +
    "</a>"
  );
};

export default {
  components: { MainContainer, TabbedDisplay },
  data: () => ({
    section: 0,
  }),

  computed: {
    bio() {
      if (!this.$store.state.bio) return [];
      const ret = this.$store.state.bio.map((item) => {
        item.content = marked(item.content, { renderer: renderer });
        return item;
      });

      return ret;
    },

    sectionList() {
      return this.bio.map(({ section }) => section);
    },

    bioSection() {
      const bio = this.bio;

      if (bio.length == 0) return "";
      if (!bio[this.section].content) return "";
      return bio[this.section].content;
    },
  },

  methods: {
    selectTab(value) {
      this.section = value;
    },
  },
};
</script>
